import Vue from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js';

export default async (context, inject) => {
    const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);
    const ROLES_SCHEMA = 'https://schemas.cabc-bchs.org/roles';

    let instance;

    const getInstance = () => instance;

    const useAuth0 = ({
        onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
        redirectUri = window.location.origin,
        ...options
    }) => {
        if (instance) return instance;

        instance = new Vue({
            data() {
                return {
                    loading: true,
                    isAuthenticated: false,
                    user: {},
                    auth0Client: null,
                    popupOpen: false,
                    error: null
                };
            },

            computed: {
                id() {
                    return this.user && this.user.sub ? this.user.sub.split(/\|/gi)[1] : null;
                },

                identityProvider() {
                    return this.user && this.user.sub ? this.user.sub.split(/\|/gi)[0] : null;
                },
            },

            methods: {
                async loginWithPopup(options) {
                    this.popupOpen = true;

                    try {
                        await this.auth0Client.loginWithPopup(options);
                        this.getUserDetails();

                    } catch (e) {
                        console.error(e);
                        this.error = e;
                    } finally {
                        this.popupOpen = false;
                    }
                },

                async handleRedirectCallback() {
                    this.loading = true;

                    try {
                        await this.auth0Client.handleRedirectCallback();
                        this.getUserDetails();

                    } catch (e) {
                        this.error = e;
                    } finally {
                        this.loading = false;
                    }
                },

                loginWithRedirect(options) {
                    return this.auth0Client.loginWithRedirect(options);
                },

                getIdTokenClaims(options) {
                    return this.auth0Client.getIdTokenClaims(options);
                },

                getTokenSilently(options) {
                    return this.auth0Client.getTokenSilently(options);
                },

                getTokenWithPopup(options) {
                    return this.auth0Client.getTokenWithPopup(options);
                },

                logout(options) {
                    return this.auth0Client.logout(options);
                },

                async getUserDetails() {
                    this.user = await this.auth0Client.getUser();
                    this.isAuthenticated = await this.auth0Client.isAuthenticated();
                    this.error = null;
                },

                isInRole(role) {
                    if (!this.isAuthenticated || !this.user || !this.user[ROLES_SCHEMA]) {
                        return false;
                    }

                    return this.user[ROLES_SCHEMA].includes(role);
                },

                async createClient() {
                    this.auth0Client = await createAuth0Client({
                        domain: options.domain,
                        client_id: options.clientId,
                        audience: options.audience,
                        redirect_uri: redirectUri
                    });

                    try {
                        if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
                            const { appState } = await this.auth0Client.handleRedirectCallback();
                            this.error = null;
                            onRedirectCallback(appState);
                        }
                    } catch (e) {
                        this.error = e;
                    } finally {
                        this.getUserDetails();
                        this.loading = false;
                    }
                }
            }
        });

        return instance;
    };

    const auth0 = await useAuth0({
        domain: 'cabc-bchs.us.auth0.com',
        clientId: 'jE2PFjNtg6yzpdmIVEuSXq3T6Zi1Uovn',
        audience: 'https://api.cabc-bchs.org',
        useRefreshTokens: true,

        onRedirectCallback: appState => {
            router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
        }
    });

    await auth0.createClient();
    inject('auth0', auth0);
}