export const AppBar = () => import('../..\\components\\app-bar.vue' /* webpackChunkName: "components/app-bar" */).then(c => wrapFunctional(c.default || c))
export const Clipboard = () => import('../..\\components\\clipboard.vue' /* webpackChunkName: "components/clipboard" */).then(c => wrapFunctional(c.default || c))
export const ConfirmDialog = () => import('../..\\components\\confirm-dialog.vue' /* webpackChunkName: "components/confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const JobSelectDialog = () => import('../..\\components\\job-select-dialog.vue' /* webpackChunkName: "components/job-select-dialog" */).then(c => wrapFunctional(c.default || c))
export const MileageDetailsDialog = () => import('../..\\components\\mileage-details-dialog.vue' /* webpackChunkName: "components/mileage-details-dialog" */).then(c => wrapFunctional(c.default || c))
export const MileageDialog = () => import('../..\\components\\mileage-dialog.vue' /* webpackChunkName: "components/mileage-dialog" */).then(c => wrapFunctional(c.default || c))
export const Pdf = () => import('../..\\components\\pdf.vue' /* webpackChunkName: "components/pdf" */).then(c => wrapFunctional(c.default || c))
export const PinDialog = () => import('../..\\components\\pin-dialog.vue' /* webpackChunkName: "components/pin-dialog" */).then(c => wrapFunctional(c.default || c))
export const PtoSelectDialog = () => import('../..\\components\\pto-select-dialog.vue' /* webpackChunkName: "components/pto-select-dialog" */).then(c => wrapFunctional(c.default || c))
export const SetupAppBar = () => import('../..\\components\\setup-app-bar.vue' /* webpackChunkName: "components/setup-app-bar" */).then(c => wrapFunctional(c.default || c))
export const SideDrawer = () => import('../..\\components\\side-drawer.vue' /* webpackChunkName: "components/side-drawer" */).then(c => wrapFunctional(c.default || c))
export const SlideSelect = () => import('../..\\components\\slide-select.vue' /* webpackChunkName: "components/slide-select" */).then(c => wrapFunctional(c.default || c))
export const TimeSelectDialog = () => import('../..\\components\\time-select-dialog.vue' /* webpackChunkName: "components/time-select-dialog" */).then(c => wrapFunctional(c.default || c))
export const TimesheetCalendar = () => import('../..\\components\\timesheet-calendar.vue' /* webpackChunkName: "components/timesheet-calendar" */).then(c => wrapFunctional(c.default || c))
export const TimesheetSummary = () => import('../..\\components\\timesheet-summary.vue' /* webpackChunkName: "components/timesheet-summary" */).then(c => wrapFunctional(c.default || c))
export const TimesheetWarningsErrors = () => import('../..\\components\\timesheet-warnings-errors.vue' /* webpackChunkName: "components/timesheet-warnings-errors" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
