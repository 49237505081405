import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ef3dad46 = () => interopDefault(import('..\\pages\\expenses\\index.vue' /* webpackChunkName: "pages/expenses/index" */))
const _b252e1f4 = () => interopDefault(import('..\\pages\\middleware\\index.vue' /* webpackChunkName: "pages/middleware/index" */))
const _01dd81f1 = () => interopDefault(import('..\\pages\\pto\\index.vue' /* webpackChunkName: "pages/pto/index" */))
const _56dfd79a = () => interopDefault(import('..\\pages\\purchasing\\index.vue' /* webpackChunkName: "pages/purchasing/index" */))
const _d9da5e12 = () => interopDefault(import('..\\pages\\signout.vue' /* webpackChunkName: "pages/signout" */))
const _6386883b = () => interopDefault(import('..\\pages\\timeclock\\index.vue' /* webpackChunkName: "pages/timeclock/index" */))
const _98258a38 = () => interopDefault(import('..\\pages\\unauthorized\\index.vue' /* webpackChunkName: "pages/unauthorized/index" */))
const _c15d6b7a = () => interopDefault(import('..\\pages\\fiscal\\expenses.vue' /* webpackChunkName: "pages/fiscal/expenses" */))
const _17e87b5c = () => interopDefault(import('..\\pages\\fiscal\\mileage.vue' /* webpackChunkName: "pages/fiscal/mileage" */))
const _684d4da6 = () => interopDefault(import('..\\pages\\fiscal\\payroll.vue' /* webpackChunkName: "pages/fiscal/payroll" */))
const _5987ea50 = () => interopDefault(import('..\\pages\\purchasing\\request-table.vue' /* webpackChunkName: "pages/purchasing/request-table" */))
const _697bea58 = () => interopDefault(import('..\\pages\\setup\\departments\\index.vue' /* webpackChunkName: "pages/setup/departments/index" */))
const _efb3fa20 = () => interopDefault(import('..\\pages\\setup\\employees\\index.vue' /* webpackChunkName: "pages/setup/employees/index" */))
const _08ffb2e4 = () => interopDefault(import('..\\pages\\setup\\expenses\\index.vue' /* webpackChunkName: "pages/setup/expenses/index" */))
const _d40e4d6e = () => interopDefault(import('..\\pages\\setup\\jobs\\index.vue' /* webpackChunkName: "pages/setup/jobs/index" */))
const _059a6977 = () => interopDefault(import('..\\pages\\setup\\locations\\index.vue' /* webpackChunkName: "pages/setup/locations/index" */))
const _728ed4eb = () => interopDefault(import('..\\pages\\setup\\positions\\index.vue' /* webpackChunkName: "pages/setup/positions/index" */))
const _9f63b1e0 = () => interopDefault(import('..\\pages\\setup\\programs\\index.vue' /* webpackChunkName: "pages/setup/programs/index" */))
const _15e8c7e8 = () => interopDefault(import('..\\pages\\supervisors\\approve.vue' /* webpackChunkName: "pages/supervisors/approve" */))
const _00d33869 = () => interopDefault(import('..\\pages\\supervisors\\review.vue' /* webpackChunkName: "pages/supervisors/review" */))
const _c03d3a86 = () => interopDefault(import('..\\pages\\timeclock\\history.vue' /* webpackChunkName: "pages/timeclock/history" */))
const _94bdf684 = () => interopDefault(import('..\\pages\\setup\\expenses\\managers\\index.vue' /* webpackChunkName: "pages/setup/expenses/managers/index" */))
const _fe240f3a = () => interopDefault(import('..\\pages\\setup\\supervisor\\groups\\index.vue' /* webpackChunkName: "pages/setup/supervisor/groups/index" */))
const _20e80858 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _12851ea6 = () => interopDefault(import('..\\pages\\setup\\expenses\\managers\\_id.vue' /* webpackChunkName: "pages/setup/expenses/managers/_id" */))
const _595690ea = () => interopDefault(import('..\\pages\\setup\\supervisor\\groups\\_id.vue' /* webpackChunkName: "pages/setup/supervisor/groups/_id" */))
const _d0869b88 = () => interopDefault(import('..\\pages\\setup\\departments\\_id.vue' /* webpackChunkName: "pages/setup/departments/_id" */))
const _06380158 = () => interopDefault(import('..\\pages\\setup\\employees\\_id.vue' /* webpackChunkName: "pages/setup/employees/_id" */))
const _8435c368 = () => interopDefault(import('..\\pages\\setup\\expenses\\_id.vue' /* webpackChunkName: "pages/setup/expenses/_id" */))
const _0162cc1e = () => interopDefault(import('..\\pages\\setup\\jobs\\_id.vue' /* webpackChunkName: "pages/setup/jobs/_id" */))
const _7e5b44c2 = () => interopDefault(import('..\\pages\\setup\\locations\\_id.vue' /* webpackChunkName: "pages/setup/locations/_id" */))
const _89d9d3da = () => interopDefault(import('..\\pages\\setup\\positions\\_id.vue' /* webpackChunkName: "pages/setup/positions/_id" */))
const _2c5aad78 = () => interopDefault(import('..\\pages\\setup\\programs\\_id.vue' /* webpackChunkName: "pages/setup/programs/_id" */))
const _e5618fac = () => interopDefault(import('..\\pages\\reports\\_.vue' /* webpackChunkName: "pages/reports/_" */))
const _829180aa = () => interopDefault(import('~/pages/purchasing/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/expenses",
    component: _ef3dad46,
    name: "expenses"
  }, {
    path: "/middleware",
    component: _b252e1f4,
    name: "middleware"
  }, {
    path: "/pto",
    component: _01dd81f1,
    name: "pto"
  }, {
    path: "/purchasing",
    component: _56dfd79a,
    name: "purchasing"
  }, {
    path: "/signout",
    component: _d9da5e12,
    name: "signout"
  }, {
    path: "/timeclock",
    component: _6386883b,
    name: "timeclock"
  }, {
    path: "/unauthorized",
    component: _98258a38,
    name: "unauthorized"
  }, {
    path: "/fiscal/expenses",
    component: _c15d6b7a,
    name: "fiscal-expenses"
  }, {
    path: "/fiscal/mileage",
    component: _17e87b5c,
    name: "fiscal-mileage"
  }, {
    path: "/fiscal/payroll",
    component: _684d4da6,
    name: "fiscal-payroll"
  }, {
    path: "/purchasing/request-table",
    component: _5987ea50,
    name: "purchasing-request-table"
  }, {
    path: "/setup/departments",
    component: _697bea58,
    name: "setup-departments"
  }, {
    path: "/setup/employees",
    component: _efb3fa20,
    name: "setup-employees"
  }, {
    path: "/setup/expenses",
    component: _08ffb2e4,
    name: "setup-expenses"
  }, {
    path: "/setup/jobs",
    component: _d40e4d6e,
    name: "setup-jobs"
  }, {
    path: "/setup/locations",
    component: _059a6977,
    name: "setup-locations"
  }, {
    path: "/setup/positions",
    component: _728ed4eb,
    name: "setup-positions"
  }, {
    path: "/setup/programs",
    component: _9f63b1e0,
    name: "setup-programs"
  }, {
    path: "/supervisors/approve",
    component: _15e8c7e8,
    name: "supervisors-approve"
  }, {
    path: "/supervisors/review",
    component: _00d33869,
    name: "supervisors-review"
  }, {
    path: "/timeclock/history",
    component: _c03d3a86,
    name: "timeclock-history"
  }, {
    path: "/setup/expenses/managers",
    component: _94bdf684,
    name: "setup-expenses-managers"
  }, {
    path: "/setup/supervisor/groups",
    component: _fe240f3a,
    name: "setup-supervisor-groups"
  }, {
    path: "/",
    component: _20e80858,
    name: "index"
  }, {
    path: "/setup/expenses/managers/:id",
    component: _12851ea6,
    name: "setup-expenses-managers-id"
  }, {
    path: "/setup/supervisor/groups/:id",
    component: _595690ea,
    name: "setup-supervisor-groups-id"
  }, {
    path: "/setup/departments/:id",
    component: _d0869b88,
    name: "setup-departments-id"
  }, {
    path: "/setup/employees/:id",
    component: _06380158,
    name: "setup-employees-id"
  }, {
    path: "/setup/expenses/:id",
    component: _8435c368,
    name: "setup-expenses-id"
  }, {
    path: "/setup/jobs/:id",
    component: _0162cc1e,
    name: "setup-jobs-id"
  }, {
    path: "/setup/locations/:id",
    component: _7e5b44c2,
    name: "setup-locations-id"
  }, {
    path: "/setup/positions/:id",
    component: _89d9d3da,
    name: "setup-positions-id"
  }, {
    path: "/setup/programs/:id",
    component: _2c5aad78,
    name: "setup-programs-id"
  }, {
    path: "/reports/*",
    component: _e5618fac,
    name: "reports-all"
  }, {
    path: "/fiscal/purchaserequests",
    component: _829180aa,
    name: "fiscal-purchase-requests"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
