export default async function ({ $auth0, route, redirect }) {
    const allowAnonymous = (route.meta[0] && route.meta[0].allowAnonymous) ?? false;
    const roles = (route.meta[0] && route.meta[0].roles);

    //anonymous routes are always allowed
    if (allowAnonymous) return;

    //otherwise, the user must at least be authenticated
    if (!$auth0.isAuthenticated) {
        return redirect('/');
    }

    //successful if route has no additional role requirements
    if (!roles) return;

    //role-based authorization
    const validRoles = roles.reduce((count, role) => $auth0.isInRole(role) ? count + 1 : count, 0);

    if (validRoles > 0) {
        return;
    } else {
        return redirect('/unauthorized');
    }
}