
export default {
	name: "side-drawer",

	mounted() {
		this.getNotifications();
		this.interval = setInterval(this.getNotifications, 5 * 60 * 1000);
	},

	data() {
		return {
			interval: undefined,
			previousNotifications: {},
		};
	},

	watch: {
		notifications: {
			immediate: true,
			deep: true,
			handler: function (value) {
				if (value.purchaseRequests !== this.previousNotifications?.purchaseRequests)
					this.grabAttention("purchaseRequests");

				if (value.expenseRequests !== this.previousNotifications?.expenseRequests)
					this.grabAttention("expenseRequests");

				this.previousNotifications = { ...value };
			},
		},
	},

	computed: {
		avatarSize: function () {
			return this.$vuetify.breakpoint.lgAndUp ? 72 : 38;
		},

		notifications: function () {
			return this.$store.state.notifications;
		},
	},

	methods: {
		getNotifications(force = false) {
			this.$store.dispatch("notifications/refresh", force);
		},

		async grabAttention(id) {
			await this.$nextTick();

			document.getElementById(`notifications__${id}`)?.classList.remove("attention");
			setTimeout(() => {
				document.getElementById(`notifications__${id}`)?.classList.add("attention");
			}, 250);
		},
	},

	beforeDestroy() {
		clearInterval(this.interval);
	},
};
