export const state = () => ({
  timesheetErrors: 0,
  purchaseRequests: 0,
  expenseRequests: 0,
  pto: 0,
});

export const mutations = {
  update(state, values) {
    state.timesheetErrors = values.timesheetErrors;
    state.purchaseRequests = values.purchaseRequests;
    state.expenseRequests = values.expenseRequests;
    state.pto = values.pto;
  },
};

export const actions = {
  async refresh({ state, commit }, force) {
    try {
      const { data } = await this.$axios.get(`/api/employees/notifications?force=${force}`);
      commit("update", data);
    } catch {}
  },
};
